import React from 'react'

import Layout from 'components/Layout/Layout'

import Showcase from '../sections/Showcase/Showcase'
import Highlights from '../sections/Highlights/Highlights'
import Connect from '../sections/Connect/Connect'
import Try from '../sections/Try/Try'

const NotFoundPage = () => {
  return (
    <Layout pageName="404">
      <section className="hero">
        <div className="row middle-xs">
          <div className="col-xs-12 col-sm-6">
            <h1 className="heading heading--big">
              Ooops. you've lost your superpowers.
              <br />
              <br />
              Get them back with Ally.
            </h1>
            <a
              href="https://app.getally.com"
              className="hero__btn menu-mobile__btn btn btn--blue btn--big"
            >
              Get Started
            </a>
          </div>
          <div className="col-xs-12 col-sm-6">
            <p className="hero__sign">404</p>
          </div>
        </div>
      </section>

      <Showcase imageName="engineers-showcase" />

      <Highlights
        items={[
          {
            heading: 'Ally provides visibility from idea to production',
            subheading:
              'Fully-customizable dashboards enable you to diagnose and act on team issues ',
            imageName: 'engineers-highlights-1.png'
          },
          {
            heading: 'Keep an eye on the progress',
            subheading:
              'Scheduled reports delivered to your chat platforms. Help your team to move work forward.',
            imageName: 'engineers-highlights-2.png'
          },
          {
            heading: 'Alerts help you stay on top of your day',
            subheading:
              'Receive alerts in Slack or Microsoft Teams when something has or has not happened.',
            imageName: 'engineers-highlights-3.png'
          },
          {
            heading: 'Do not miss anything important with notifications',
            subheading:
              'Extended filters and configurations enable you reduce noise and do not miss important events. Get notifications delivered to your chat platform.',
            imageName: 'engineers-highlights-4.png'
          }
        ]}
        startsLeft
      />

      <Connect />

      <Try isSolutions />
    </Layout>
  )
}

export default NotFoundPage
